import Head from 'next/head';
import React from 'react';
import type { FC } from 'react';

type Props = {
  canonical?: string;
  description: string;
  noIndex?: boolean;
  ogDescription?: string;
  ogImage?: string;
  ogTitle?: string;
  ogUrl?: string;
  title: string;
  twitterCard?: string;
  twitterSite?: string;
};

const SEO: FC<Props> = ({
  canonical,
  description,
  noIndex,
  ogDescription,
  ogImage,
  ogTitle,
  ogUrl,
  title,
  twitterCard,
  twitterSite,
}) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    {noIndex && <meta name="robots" content="noindex" />}
    {canonical && <link rel="canonical" href={canonical} />}

    {twitterCard && <meta name="twitter:card" content={twitterCard} />}
    {twitterSite && <meta name="twitter:site" content={twitterSite} />}

    {ogTitle && <meta property="og:title" content={ogTitle} />}
    {ogDescription && (
      <meta property="og:description" content={ogDescription} />
    )}
    {ogUrl && <meta property="og:url" content={ogUrl} />}
    {ogImage && <meta property="og:image" content={ogImage} />}
  </Head>
);
export default SEO;
