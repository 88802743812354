import { useRouter } from 'next/router';
import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import { AnyObject } from 'core/utils';
import fetcher from 'core/utils/fetcher';

import type { SearchApiResponse } from 'pages/api/search';
import { searchApiRoute } from 'routes';

const useSearch = (params?: AnyObject, options?: SWRConfiguration) => {
  const router = useRouter();

  const _params = new URLSearchParams(params);

  for (const param of Object.keys(router.query)) {
    if (Array.isArray(router.query[param])) {
      for (const arrayParam of router.query[param]) {
        _params.append(param, arrayParam);
      }
    } else {
      _params.append(param, router.query[param] as string);
    }
  }

  const url = `${searchApiRoute()}${
    _params.toString() && `?${_params.toString()}`
  }`;

  return useSWR<SearchApiResponse>(router.isReady && url, fetcher, options);
};

export default useSearch;
