import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import { isNumeric, validEnum } from '../utils';

import s from './Skeleton.module.scss';

export enum SkeletonVariant {
  Rect = 'rect',
  Circle = 'circle',
}

interface Styles {
  width?: string;
  height?: string;
}

type Props = {
  classNames?: {
    root?: string;
    content?: string;
  };
  height?: number | string;
  variant?: SkeletonVariant;
  width?: number | string;
};

const Skeleton: FC<Props> = ({
  children,
  classNames = {
    root: '',
    content: '',
  },
  height,
  variant = SkeletonVariant.Rect,
  width,
}) => {
  if (!validEnum(SkeletonVariant, variant)) {
    variant = SkeletonVariant.Rect;
  }

  const styles: Styles = {};

  if (width) {
    if (isNumeric(width)) {
      styles.width = `${width}px`;
    } else {
      styles.width = width as string;
    }
  }

  if (height) {
    if (isNumeric(height)) {
      styles.height = `${height}px`;
    } else {
      styles.height = height as string;
    }
  }

  return (
    <div
      className={cn(
        s.root,
        variant === SkeletonVariant.Rect && s.variantRect,
        variant === SkeletonVariant.Circle && s.variantCircle,
        classNames.root
      )}
      style={styles}>
      <span className={cn(s.content, classNames?.content)}>{children}</span>
    </div>
  );
};

export default Skeleton;
