import Link from 'next/link';
import React from 'react';
import type { FC } from 'react';

import Button from 'core/components/Button';
import ExploreIcon from 'core/icons/ExploreIcon';
import RocketIcon from 'core/icons/RocketIcon';

import PageContainer from 'components/PageContainer';
import UserAlerts from 'components/UserAlerts';
import CreatorResult from 'components/pages/search/CreatorResult';
import SEO from 'components/SEO';
import { SHOW_HOMEPAGE_CREATORS } from 'config';
import useSearch from 'hooks/data/useSearch';
import { accountCreatorRoute, faqRoute, searchRoute } from 'routes';

import s from './IndexPage.module.scss';

const IndexPage: FC = () => {
  const { data: launchPartnerCreatorsData } = useSearch(
    {
      launchPartner: true,
      sort: 'random',
      perPage: 10,
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const { data: popularCreatorsData } = useSearch({
    sort: 'popular',
    perPage: 10,
  });
  const { data: recentCreatorsData } = useSearch({
    sort: 'recent',
    perPage: 10,
  });

  return (
    <PageContainer className={s.root}>
      <SEO title="SexWorker - The adult creator database" />
      <UserAlerts />
      <div className={s.features}>
        <a href={searchRoute()} className={s.feature}>
          <h2 className={s.heading}>Discover Creators</h2>
          <div className={s.subheading}>Explore our creator database</div>
          <div className={s.icon}>
            <ExploreIcon className={s.svgIcon} />
          </div>
          <div className={s.ctaLink}>Explore -&gt;</div>
        </a>
        <a href={accountCreatorRoute()} className={s.feature}>
          <h2 className={s.heading}>Become a Creator</h2>
          <div className={s.subheading}>
            Add your profile and grow your audience
          </div>
          <div className={s.icon}>
            <RocketIcon className={s.svgIcon} />
          </div>
          <div className={s.ctaLink}>Apply Now -&gt;</div>
        </a>
      </div>
      <div className={s.noticeSection}>
        <div className={s.headingContainer}>
          <h2 className={s.heading}>Welcome</h2>
        </div>
        <div className={s.noticeDescription}>
          SexWorker is a database of adult content creators.
          <br />
          <br />
          The goal of this project is to drive more traffic to creator&apos;s
          platforms and increase their earnings.
          <br />
          <br />
          Creators add their profiles to the{' '}
          <Link href={searchRoute()}>
            <a>Explore</a>
          </Link>{' '}
          page.
          <br />
          Customers discover new creators.
          <br />
          <br />
          We are currently testing the platform and we invite creators to signup
          and get listed.
        </div>
        <div className={s.noticeButtons}>
          <Button
            classNames={{ root: s.noticeButtonRoot }}
            href={searchRoute()}
            icon={ExploreIcon}
            text="View Creators"
          />
          <Button
            classNames={{ root: s.noticeButtonRoot }}
            href={accountCreatorRoute()}
            icon={RocketIcon}
            text="Become a Creator"
          />
        </div>
        <div className={s.faqLink}>
          <Link href={faqRoute()}>
            <a>Frequently Asked Questions</a>
          </Link>
        </div>
      </div>
      {SHOW_HOMEPAGE_CREATORS && (
        <>
          <div className={s.creatorsSection}>
            <div className={s.headingContainer}>
              <h2 className={s.heading}>Launch Partners</h2>
            </div>
            <div className={s.creators}>
              {launchPartnerCreatorsData ? (
                <>
                  {launchPartnerCreatorsData.users.map((user, i) => (
                    <CreatorResult key={i} user={user} />
                  ))}
                </>
              ) : (
                <>
                  {[...Array(20)].map((_, i) => (
                    <CreatorResult key={i} fallback={true} />
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={s.creatorsSection}>
            <div className={s.headingContainer}>
              <h2 className={s.heading}>Popular Creators</h2>
              <Button
                classNames={{ root: s.buttonRoot }}
                href={`${searchRoute()}?sort=popular`}
                subtle={true}
                text="Show More -&gt;"
              />
            </div>
            <div className={s.creators}>
              {popularCreatorsData ? (
                <>
                  {popularCreatorsData.users.map((user, i) => (
                    <CreatorResult key={i} user={user} />
                  ))}
                </>
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <CreatorResult key={i} fallback={true} />
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={s.creatorsSection}>
            <div className={s.headingContainer}>
              <h2 className={s.heading}>New Creators</h2>
              <Button
                classNames={{ root: s.buttonRoot }}
                href={`${searchRoute()}?sort=recent`}
                subtle={true}
                text="Show More -&gt;"
              />
            </div>
            <div className={s.creators}>
              {recentCreatorsData ? (
                <>
                  {recentCreatorsData.users.map((user, i) => (
                    <CreatorResult key={i} user={user} />
                  ))}
                </>
              ) : (
                <>
                  {[...Array(10)].map((_, i) => (
                    <CreatorResult key={i} fallback={true} />
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default IndexPage;
