import cn from 'classnames';
import React from 'react';
import type { FC, ReactNode } from 'react';

import { ColorVariant, validEnum } from '../utils';

import s from './Alert.module.scss';

export enum AlertSize {
  Small = 'small',
  Standard = 'standard',
}

type Props = {
  children?: ReactNode;
  classNames?: {
    root?: string;
  };
  size?: AlertSize;
  variant?: ColorVariant;
};

const Alert: FC<Props> = ({
  children,
  classNames = {
    root: '',
  },
  size = AlertSize.Standard,
  variant = ColorVariant.Primary,
}) => {
  if (!validEnum(ColorVariant, variant)) {
    variant = ColorVariant.Primary;
  }

  return (
    <div
      className={cn(
        s.root,
        size === AlertSize.Small && s.sizeSmall,
        size === AlertSize.Standard && s.sizeStandard,
        variant === ColorVariant.Primary && s.variantPrimary,
        variant === ColorVariant.Secondary && s.variantSecondary,
        variant === ColorVariant.Success && s.variantSuccess,
        variant === ColorVariant.Danger && s.variantDanger,
        variant === ColorVariant.Warning && s.variantWarning,
        variant === ColorVariant.Info && s.variantInfo,
        classNames.root
      )}>
      {children}
    </div>
  );
};

export default Alert;
