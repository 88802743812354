import React from 'react';
import type { FC } from 'react';

import Alert from 'core/components/Alert';
import { typeToColorVariant, typeToString } from 'core/models/UserAlert';

import useUser from 'hooks/data/account/useUser';

import s from './UserAlerts.module.scss';

const UserAlerts: FC = () => {
  const { data: userData } = useUser();

  return (
    <div className={s.root}>
      {userData?.user?.alerts?.length > 0 && (
        <>
          {userData?.user?.alerts?.map(({ type }, i) => (
            <Alert
              key={i}
              classNames={{ root: s.alert }}
              variant={typeToColorVariant(type)}>
              <div>{typeToString(type)}</div>
            </Alert>
          ))}
        </>
      )}
    </div>
  );
};

export default UserAlerts;
