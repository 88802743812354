import cn from 'classnames';
import Link from 'next/link';
import React, { forwardRef } from 'react';
import type { FC, ElementType, ReactNode, Ref } from 'react';

import { AnyObject, ColorVariant, validEnum } from '../utils';
import Skeleton from './Skeleton';

import s from './Button.module.scss';

export enum ButtonDisplay {
  Block = 'block',
  InlineBlock = 'inline-block',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export type ButtonProps = {
  classNames?: {
    root?: string;
    button?: string;
    icon?: string;
    svgIcon?: string;
    text?: string;
  };
  disabled?: boolean;
  display?: ButtonDisplay;
  fallback?: boolean;
  href?: string;
  icon?: ElementType;
  loading?: boolean;
  onClick?: (event?) => void;
  outlined?: boolean;
  ref?: Ref<HTMLElement> | Ref<undefined>;
  rounded?: boolean;
  style?: AnyObject;
  subtle?: boolean;
  tabIndex?: number;
  target?: string;
  text?: string | ReactNode;
  type?: ButtonType;
  variant?: ColorVariant;
};

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      classNames = {
        root: '',
        button: '',
        icon: '',
        svgIcon: '',
        text: '',
      },
      disabled = false,
      display = ButtonDisplay.InlineBlock,
      fallback,
      href,
      icon,
      loading = false,
      onClick,
      outlined = false,
      rounded = false,
      tabIndex,
      target,
      text,
      type = ButtonType.Button,
      style = {},
      subtle = false,
      variant = ColorVariant.Primary,
    },
    ref
  ) => {
    if (!validEnum(ButtonDisplay, display)) {
      display = ButtonDisplay.InlineBlock;
    }

    if (!validEnum(ButtonType, type)) {
      type = ButtonType.Button;
    }

    const Icon = icon;

    const inner = (
      <button
        className={cn(s.button, classNames.button)}
        type={type}
        disabled={disabled || loading}
        tabIndex={tabIndex}
        onClick={onClick}>
        {text && <div className={cn(s.text, classNames.text)}>{text}</div>}
        <div className={s.iconContainer}>
          {loading && <div className={cn(s.loadingIcon, classNames.icon)} />}
          {icon && !loading && (
            <div className={cn(s.icon, classNames.icon)}>
              <Icon className={cn(s.svgIcon, classNames.svgIcon)} />
            </div>
          )}
        </div>
      </button>
    );

    return (
      <div
        ref={ref as Ref<HTMLDivElement>}
        className={cn(
          s.root,
          disabled && s.disabled,
          display === ButtonDisplay.Block && s.displayBlock,
          display === ButtonDisplay.InlineBlock && s.displayInlineBlock,
          fallback && s.fallback,
          loading && s.loading,
          outlined && s.outlined,
          subtle && s.subtle,
          icon && s.hasIcon,
          rounded && s.rounded,
          text && s.hasText,
          variant === ColorVariant.Primary && s.variantPrimary,
          variant === ColorVariant.Secondary && s.variantSecondary,
          variant === ColorVariant.Success && s.variantSuccess,
          variant === ColorVariant.Danger && s.variantDanger,
          variant === ColorVariant.Warning && s.variantWarning,
          variant === ColorVariant.Info && s.variantInfo,
          variant === ColorVariant.Neutral && s.variantNeutral,
          variant === ColorVariant.Transparent && s.variantTransparent,
          classNames.root
        )}
        style={style}>
        {!fallback ? (
          <>
            {href ? (
              <Link href={href}>
                <a target={target}>{inner}</a>
              </Link>
            ) : (
              <>{inner}</>
            )}
          </>
        ) : (
          <Skeleton
            classNames={{ root: s.fallback, content: s.fallbackContent }}>
            {icon && <div className={s.fallbackIcon}></div>}
            {text && (
              <div suppressHydrationWarning>
                {text || '. . . . . . . . . . . . . . . . . . .'}
              </div>
            )}
          </Skeleton>
        )}
      </div>
    );
  }
);

Button.displayName = 'Button';

export default Button;
